import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  BrowserRouter,
  Route,
  useLocation,
  Switch,
  Redirect,
  StaticRouter,
  useHistory
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import {
  useSelector
} from 'react-redux';
import {
  TransitionGroup,
  CSSTransition
} from 'react-transition-group';
import loadable from "@loadable/component";
import { isDesktop } from 'react-device-detect';
import { findPath, isPathRoute } from "./utils"

//#region //// TBX-WIDGETS /////
import {
  cpassAuthService
} from '@tbx/experience-widgets-lib';
//#endregion

//#region /////// CONTAINERS ///////
import HomeSection from '../sections/HomeSection';
import PlayerSection from '../sections/PlayerSection';
import ContentOverViewSection from '../sections/ContentOverViewSection';
import ContentOverViewLiveSection from '../sections/ContentOverViewLiveSection';
import Navbar from '../Navbar';
import NotFound from '../NotFound';
import { HandleError, RedirectHandleError } from '../../components/HandleError';
//#endregion

import * as hooks from './hooks';
import useStoreTime from '../../customHooks/useStoreTime';
import * as selectors from './selectors';
import './styles.scss';
import ActivateCodeSection from '../sections/ActivateCodeSection';
import { CONTENT_PROVIDER_AUTH, DEFAULT_CONTENT_PROVIDER_AUTH } from '../../constants/contentProviderAuth';
import LandingLoader from '../../components/LandingLoader';
import PlayerCallbackContextProvider from '../App/playerCallbackContextProvider';
import { getIdpClient } from '../../utils/cloudpassUtilis';
import { PATH_ERROR } from './constants';

const LoadableSearchSection = loadable(() => import('../sections/SearchSection'));
const LoadableSection = loadable(() => import('../sections/Section'));

const hasHomeSection = (sectionCollection) => !!sectionCollection.find(item => item.isHome && item.isHome === true);

const getSectionMessage = (error, sectionCollection, t) => {
  const { code, message } = error;

  if (sectionCollection.length === 0) {
    return {
      error: t('There are no sections available. Check with the system administrator.')
    }
  }

  if (!hasHomeSection(sectionCollection)) {
    //debugger;
    return {
      error: t('There is no home section available. Check with the system administrator.')
    }
  }

  if (code && message) {
    return { ...error };
  }

};

/**
 * Redirige a la URL de login proporcionada por CloudPass
 *
 * @param {*} props
 * @returns
 */
function GoToLogin(props) {
  const { state } = props.location;
  let loginURL = null;
  const { TBX_FORCE_LOGIN_URL } = window.__TBX_ENV__;
  const unityAuthData = useSelector(state => selectors.selectUnityAuthData(state));
  const { projectShortCode } = useSelector(state => selectors.selectAppSettings(state));
  const currentIdp  = useSelector(state => selectors.selectCurrentIdp(state));
  const idpShortNameDefault  = useSelector(state => selectors.selectIdpShortNameDefault(state));

  const idpClient = getIdpClient(unityAuthData.client);
  console.log('CONTENT_PROVIDER_AUTH: ', CONTENT_PROVIDER_AUTH,idpClient, currentIdp)

  if (TBX_FORCE_LOGIN_URL) {
    const userCountry = unityAuthData.country || unityAuthData.ipUserCountry ;
    loginURL = `${TBX_FORCE_LOGIN_URL}?failureRedirect=${encodeURIComponent(state?.returnURL)}&response_type=code&redirect_uri=${encodeURIComponent(state?.returnURL)}&client_id=${idpClient}&country=${userCountry}`;
  } else {
    loginURL = cpassAuthService.getLoginURL(
      projectShortCode || idpClient,
      currentIdp || idpShortNameDefault,
      currentIdp ? unityAuthData.country : DEFAULT_CONTENT_PROVIDER_AUTH.countryCode,
      state.returnURL,
      null
    );
  }

  window.location.replace(loginURL);
  return null;
}

/**
 * Procesa el logout en CloudPass y redirige la inicio
 *
 * @param {*} props
 * @returns
 */
function GoUserLogout(props) {
  const { projectShortCode } = useSelector(state => selectors.selectAppSettings(state));
  React.useEffect(() => {
    const { state } = props.location;
    //const appSettings = useSelector(state => selectors.selectAppSettings(state));
    //TODO: Obtener el idp client_id e idpShortCode de appSettings.
    const logoutURL = cpassAuthService.getLogoutURL(projectShortCode, state.returnURL + '?logout=true');
    window.location.replace(logoutURL);
  }, []);
  return null;
}


function PrivateRoute({ children, ...rest }) {
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));
  const PATHS = useSelector(state => selectors.selectPathList(state));

  return (
    <Route
      {...rest}
      render={(props) => {
        const { origin } = window.location;
        const returnURL = origin + PATHS.active;

        return authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: PATHS.login,
              state: { returnURL },
            }}
          />
        );
      }}
    />
  );
}

/**
 * Renderiza las secciones y las transiciones entre las mismas.
 *
 * @param {*} { sectionCollection }
 * @returns
 */
function AppRoutes({ sectionCollection }) {
  const location = useLocation();
  const homeSectionData = sectionCollection.filter(item => item.isHome && item.isHome === true)[0];
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));

  //#region ////// SELECTORS ///////
  const accessToken = useSelector(state => selectors.selectAccessToken(state));
  const PATHS = useSelector(state => selectors.selectPathList(state));
  const pathCountry = useSelector(state => selectors.selectPathCountry(state));
  //#endregion

  const storeTime = useStoreTime()
  const [redirectPathStateId, redirectPathStateUrl] = findPath(location.pathname, PATHS, pathCountry);

  return (
    <TransitionGroup className={'tbxAppRoutes'}>
      {/*
      This is no different than other usage of
      <CSSTransition>, just make sure to pass
      `location` to `Switch` so it can match
      the old location as it animates out.
    */}
      <CSSTransition
        key={location.key}
        classNames='fade'
        timeout={300}
      >
        <Switch location={location} >
          <Route exact path={PATHS.search} component={LoadableSearchSection} />
          <Route exact path={PATHS.home}>
            <HomeSection accessToken={accessToken} section={homeSectionData} />
          </Route>
          <Route exact path={PATHS.player}>
            <PlayerSection accessToken={accessToken} />
          </Route>
          <Route exact path={PATHS.content}>
            <ContentOverViewSection accessToken={accessToken} sectionCollection={sectionCollection.filter(item => item.inMenu && item.inMenu === true)} storeTime={storeTime} />
          </Route>
          <Route exact path={PATHS.live}>
            <ContentOverViewLiveSection accessToken={accessToken} sectionCollection={sectionCollection.filter(item => item.inMenu && item.inMenu === true)} storeTime={storeTime} />
          </Route>
          <Route path={PATHS.logout} component={GoUserLogout} />
          <Route path={PATHS.login} component={GoToLogin} />


          <PrivateRoute path={PATHS.active}>
            <ActivateCodeSection />
          </PrivateRoute>
          <Route path={PATH_ERROR} component={RedirectHandleError} />
          <Route path='/:slug'
            children={(props) => {
              const pathname = location.pathname

              if (!isPathRoute(location.pathname, PATHS) && redirectPathStateUrl) return (<Redirect push={true} to={redirectPathStateUrl} />)

              const section = sectionCollection.find(i => pathCountry + i.url === pathname);
              if (!section) {
                console.error(`>>> section to match:"${pathname}" not found!`);
                return <NotFound />;
              }

              const externalURL = section?.redirect?.externalURL

              if (externalURL) return window.location.replace(externalURL);

              if(!section.isPublic && !authenticated) {
                return (<Redirect
                  to={{
                    pathname: PATHS.login,
                    state: { returnURL:  window.location.origin+section.url },
                  }}></Redirect>)
              }
            
              return (
                <LoadableSection {...props} accessToken={accessToken} section={section} />
              );
            }}
          />
          <Route children={(props) => {
            const pathname = location.pathname
            if(pathname === '' || pathname === '/') return (<Redirect push={true} to={PATHS.home} />)
            return (<NotFound />);
          }} />
        </Switch>
      </CSSTransition>
    </TransitionGroup >
  );
}

/**
 * Navbar and setup routes
 *
 * @param {*} props
 * @returns
 */
function AppSectionRouter(props) {
  const { t } = useTranslation();
  //#region ////// SELECTORS ///////
  const accessToken = useSelector(state => selectors.selectAccessToken(state));
  const error = useSelector(state => selectors.selectError(state));
  const isFetching = useSelector(state => selectors.selectIsFetching(state));
  const pathCountry = useSelector(state => selectors.selectPathCountry(state));
  const sectionCollection = useSelector(state => selectors.selectSectionCollection(state));
  //#endregion

  //#region /////// HOOK EFFECTS ///////
  hooks.useSectionsFetch(accessToken);
  hooks.usePathsHok(pathCountry); // importante para que las rutas funcionen
  //#endregion

  const errorResult = getSectionMessage(error, sectionCollection, t);

  const sectionsMenu = sectionCollection.filter(item => item.inMenu && item.inMenu === true)
  const subSections = sectionCollection.filter(item => item.parent && typeof item.inMenu === "boolean" && item.inMenu === false)
  return (
    <ConnectedRouter history={props.history}>
      <BrowserRouter>
        <PlayerCallbackContextProvider>
          {isDesktop && <Navbar
            sections={sectionsMenu}
            subSections={subSections}
          />}
          {
            isFetching &&
            <main className="app-section-load">
              <LandingLoader
                className="app-section-load__loadingSpinner"
              />
            </main>
          }
          {
            !isFetching &&
            (errorResult ?
              <HandleError error={errorResult} navBar={false} />
              :
              <AppRoutes sectionCollection={sectionCollection} />
            )
          }
        </PlayerCallbackContextProvider>
      </BrowserRouter>
    </ConnectedRouter>
  );
}

AppSectionRouter.propTypes = {
  history: PropTypes.object.isRequired
};

export default AppSectionRouter;
