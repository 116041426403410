import React from 'react';
import { useTranslation } from 'react-i18next';

import logoImg from '../../static/images/logo.png';
import './styles.scss';

import { Imgcomponent } from '@tbx/experience-widgets-lib';

function DownloadApp() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className={'tbxDownloadApp'}>
        <Imgcomponent
          className={'tbxDownloadApp_logoImg'}
          src={logoImg}
          loading={'eager'}
        />
        <div className="tbxDownloadApp_card">
          <p>
            {t(
              "Access different types of content at no additional cost: Sports, Children's content, Movies, Series among others from your Computer"
            )}
          </p>
          <span>
            {t(
              'Exclusive service for our customers who have television services'
            )}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DownloadApp;
