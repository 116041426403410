import {
  all,
  call,
  put,
  select,
  take,
  takeLatest,
  delay,
  cancel,
  cancelled,
  fork
} from 'redux-saga/effects';
import {
  cpassAuthService,
  unityAuthService,
  unityRatingService
} from '@tbx/experience-widgets-lib';

import {
  decodeToken
} from '../../utils/jwtUtils';
import * as actions from './actions';
import { types } from './constants';
import * as selectors from './selectors';
import { CONTENT_PROVIDER_AUTH } from '../../constants/contentProviderAuth';
import { fetchProfiles } from '../ProfileManager/actions';
import { TIMEOUT_PARAMETER } from '../../constants/delayTime';
import { useDispatch } from 'react-redux';
import { getIdpClient } from '../../utils/cloudpassUtilis';
import CONFIG from '../../config';
import i18n from '../../config/i18n';

function* getJwtToken(action) {

  function* handleGetPublicAuth({
    country,
    currentProfile,
    device,
    language,
    oneTimeToken
  }) {
    const { result } = yield call(unityAuthService.getPublicAuth, country, currentProfile, device, language, oneTimeToken);
    if (!result || result.error) {
      throw new Error(result);
    }
    !!result.language && i18n.changeLanguage(result.language);

    yield all([
      put(actions.loadUnityToken(result))
    ]);
  }

  function* handleError(e) {
    const errObj = e.error ? e.error : e;
    console.error('GET_UNITY_TOKEN_ERROR: ', errObj);
    yield put(actions.loadUnityTokenError(errObj));
  }

  try {
    yield handleGetPublicAuth(action);
  } catch (e) {
    try {
      const codeError = e?.code;

      let authConfig = null;

      if (codeError === "PA-004") {
        authConfig = {
          ...action,
          currentProfile: null
        }
      }

      if (codeError === "IP-002") {
        authConfig = {
          ...action,
          currentProfile: null,
          device: null
        }
      }

      if(!authConfig) yield handleError(e)

      if(authConfig) yield handleGetPublicAuth(authConfig)


    } catch (e) {
      yield handleError(e)
    }
  }
}

/**
 * Get Toolbox User Token (device) from pass and regenerate JWT.
 *
 * @param {*} action
 */
function* getLoginDevice(action) {
  const { loginCode, redirectURI } = action;
  const currentToken = yield select((state) => selectors.selectAccessToken(state));
  const tokenInfo = decodeToken(currentToken.access_token);
  const idpClient = getIdpClient(tokenInfo.client)

  try {

    const { result } = yield call(cpassAuthService.getDeviceOauth2Token, {
      clientID: idpClient,
      code: loginCode,
      redirectURI
    });

    if (!result || !result['access_token'] || result.error) {
      throw new Error(result);
    }

    const device = result['access_token'];
    const payload = {
      client: tokenInfo.client,
      country: tokenInfo.country,
      currentProfile: tokenInfo.profile, //TODO: Para completar el flujo de perfiles.
      device,
      language: tokenInfo.language
    };
    yield put(actions.requestUnityToken(payload));
  } catch (e) {
    console.error(`[${action.type}] getLoginDevice Saga error on get device token from clodupass: `, e);
    yield put(actions.authnLoginFailed(e.error));
  }
}

/**
 * Get Toolbox User One time Token.
 *
 * @param {*} action
 */
function* getOTTLoginDevice(action) {
  const { one_time_token: oneTimeToken } = action;
  let currentToken = yield select((state) => selectors.selectAccessToken(state));
  const tokenInfo = decodeToken(currentToken.access_token);

  try {
    const payload = {
      client: tokenInfo.client,
      country: tokenInfo.country,
      currentProfile: tokenInfo.profile,
      device: null,
      language: tokenInfo.language,
      oneTimeToken
    };

    yield put(actions.requestUnityToken(payload));
    yield take(types.APP_TOKEN_FETCH_SUCCESS);

    currentToken = yield select((state) => selectors.selectAccessToken(state));

    const [{ result: configResult }, { result: networkResult }, { result: channelResult }] = yield all([
      call(unityAuthService.getClientConfig, currentToken),
      call(unityAuthService.getNetworks, currentToken),
      call(unityAuthService.getChannels, currentToken)
    ]);

    if (!configResult || configResult.error || !networkResult || networkResult.error || !channelResult || channelResult.error) {
      const errorResult = configResult || networkResult || channelResult;
      throw new Error(errorResult);
    }

    yield put(actions.appStartupSuccess(configResult, networkResult, channelResult));

  } catch (e) {
    console.error(`[${action.type}] getOTTLoginDevice Saga error from clodupass: `, e);
    yield put(actions.authnOTTLoginFailed(e.error));
  }
}

/**
 * Get startup JWT token
 *
 * @param {*} action
 * @returns
 */
function* getStartupRequiredData(action) {
  const { oneTimeToken } = action;
  let currentToken, payload = {};
  currentToken = yield select((state) => selectors.selectAccessToken(state));
  const jwtToken = currentToken && currentToken['access_token'];

  if (jwtToken) {
    const data = decodeToken(jwtToken);
    const countryResult = data.country;
    if (oneTimeToken) {
      payload = {
        client: data.client,
        country: countryResult,
        currentProfile: data.profile,
        device: null,
        language: data.language,
        oneTimeToken
      };
    }
    else {

      payload = {
        client: data.client,
        country: countryResult,
        currentProfile: data.profile,
        device: data.device,
        language: data.language
      };

    }
  }

  yield put(actions.requestUnityToken(payload));
  yield take(types.APP_TOKEN_FETCH_SUCCESS);

  currentToken = yield select((state) => selectors.selectAccessToken(state));

  try {
    const [{ result: configResult }, { result: networkResult }, { result: channelResult }] = yield all([
      call(unityAuthService.getClientConfig, currentToken),
      call(unityAuthService.getNetworks, currentToken),
      call(unityAuthService.getChannels, currentToken)
    ]);

    if (!configResult || configResult.error || !networkResult || networkResult.error || !channelResult || channelResult.error) {
      const errorResult = configResult || networkResult || channelResult;
      throw new Error(errorResult);
    }

    yield put(actions.appStartupSuccess(configResult, networkResult, channelResult));
  } catch (e) {
    console.error('GET_UNITY_CLIENT_CONFIG_ERROR: ', e);
    yield put(actions.loadUnityTokenError(e.error));
  }
}

/**
 * Refresh invalid JWT Token.
 *
 * @param {*} action
 */
function* refreshJwtToken(action) {
  const { nextAction } = action;
  let currentToken = yield select((state) => selectors.selectAccessToken(state));
  const jwtToken = currentToken['access_token'];
  const {
    country,
    currentProfile,
    device,
    language
  } = decodeToken(jwtToken);

  try {
    const { result } = yield call(unityAuthService.getPublicAuth, country, currentProfile, device, language);

    if (!result || result.error || !result.token) {
      throw new Error(result);
    }

    yield all([
      put(actions.refreshedUnityToken(result.token)),
      put({
        ...nextAction,
        accessToken: result.token
      })
    ]);
  } catch (e) {
    console.error('REFRESH_UNITY_TOKEN_ERROR: ', e);
  }
}

function* fetchProfilesSagaPeriodically() {

  try {
    while (true) {
      yield put(fetchProfiles(null));
      yield delay(TIMEOUT_PARAMETER.FETCH_PROFILES);
    }
  } catch (e) {
    console.error('FETCH_PROFILES_SAGAS_PERIODICALLY: ', e);
  }
  finally {
    if (yield cancelled()) {
      console.log('FETCH_PROFILES_SAGAS_PERIODICALLY: CANCELED');
    }
  }
}

function* listenBackgroundProcess() {

  while (yield take(types.START_BACKGROUND_FETCH_PROFILES)) {

    const bgSyncTask = yield fork(fetchProfilesSagaPeriodically)

    yield take(types.STOP_BACKGROUND_FETCH_PROFILES);

    yield cancel(bgSyncTask);
  }

}

function* fetchImagesRating(action){
  const {
    accessToken,
  } = action;

  try {
    const { result } =  yield call(unityRatingService.getRatingsImages, accessToken);

    if (!result || result.error) {
      throw new Error(result.error);
    }

    yield put(actions.getRatingImagesSuccess(result));
    

  } catch (e) {
    console.error('RATING_IMAGES_FETCH_ERROR: ', e);
    yield put(actions.getRatingImagesError(e));
  }

}

function* fetchIdpsContentProvider(){

  yield take(types.APP_STARTUP_SUCCESS);
  const { projectShortCode: client } = yield select((state) => selectors.selectAppSettings(state));

  try {
    const { result } =  yield call(cpassAuthService.getIdpsOfContentProvider, client);

    if (!result || result.error) {
      throw new Error(result.error);
    }

    yield put(actions.getIdpsContentProviderSuccess(result));
    
  } catch (e) {
    console.error('IDPS_CONTENT_PROVIDER_FETCH_ERROR: ', e);
    yield put(actions.getIdpsContentProviderError(e));
  }

}

function* saga() {
  yield takeLatest(types.APP_DEVICE_AUTHN, getLoginDevice);
  yield takeLatest(types.APP_DEVICE_AUTHN_OTT, getOTTLoginDevice);
  yield takeLatest(types.APP_STARTUP, getStartupRequiredData);
  yield takeLatest(types.APP_TOKEN_FETCH, getJwtToken);
  yield takeLatest(types.APP_TOKEN_REFRESH, refreshJwtToken);
  yield takeLatest(types.LISTEN_BACKGROUND_PROCESS, listenBackgroundProcess);
  yield takeLatest(types.RATING_IMAGES_FETCH, fetchImagesRating);
  yield takeLatest(types.IDPS_CONTENT_PROVIDER_FETCH, fetchIdpsContentProvider);
}

export default saga;