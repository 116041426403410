import { types, TOAST_TYPES } from './constants';

export const appStartup = (oneTimeToken) => ({
  type: types.APP_STARTUP,
  oneTimeToken
});

export const appStartupSuccess = (settings, networks, channels) => ({
  type: types.APP_STARTUP_SUCCESS,
  settings,
  networks,
  channels
});

export const authnLogin = (loginCode, redirectURI) => ({
  type: types.APP_DEVICE_AUTHN,
  loginCode,
  redirectURI
});

export const authnOTTLogin = (one_time_token) => ({
  type: types.APP_DEVICE_AUTHN_OTT,
  one_time_token
});


export const authnLoginFailed = (error) => ({
  type: types.APP_DEVICE_AUTHN_FAILURE,
  error
});

export const authnOTTLoginFailed = (error) => ({
  type: types.APP_DEVICE_AUTHN_OTT_FAILURE,
  error
});

export const deviceLogout = () => ({
  type: types.APP_DEVICE_LOGGED_OUT
});

export const loadUnityToken = (unityAuthData) => ({
  type: types.APP_TOKEN_FETCH_SUCCESS,
  unityAuthData
});

export const loadUnityTokenError = (error) => ({
  type: types.APP_TOKEN_FETCH_FAILURE,
  error
});

export const refreshUnityToken = (nextAction) => ({
  type: types.APP_TOKEN_REFRESH,
  nextAction
});

export const refreshedUnityToken = (tokenData) => ({
  type: types.APP_TOKEN_REFRESH_SUCCESS,
  tokenData
});

export const requestUnityToken = ({
  country = null,
  currentProfile = null,
  device = null,
  language = null, 
  oneTimeToken= null
}) => ({
  type: types.APP_TOKEN_FETCH,
  country,
  currentProfile,
  device,
  language,
  oneTimeToken
});

export const toggleProfilesModal = (show) => ({
  type: types.APP_TOGGLE_PROFILES_MODAL,
  show
});

export const notificationModalCreate = ({
    title = null,
    message = null,
    htmlText = null,
    error = null,
    logoImg = false,
    firstButton = null,
    secondButton = null,
    errorCode = false,
    className = '',
    exitButton = false
  }) => ({
    type: types.APP_NOTIFICATION_MODAL_CREATE,
    title,
    message,
    htmlText,
    error,
    logoImg,
    firstButton,
    secondButton,
    errorCode,
    className,
    exitButton
})

export const notificationModalDelete = () => ({
  type: types.APP_NOTIFICATION_MODAL_DELETE,
})

export const toastAdd = ({
  toastType = TOAST_TYPES.INFO,
  title = null,
  message = null,
  temporary = true,
  time = 10000, 
  error = null
})=>({
  type: types.APP_TOAST_ADD,
  toastType,
  title,
  message,
  temporary,
  time,
  error
});


export const toastDelete = (
  key = null
)=>({
  type: types.APP_TOAST_DELETE,
  key
});

export const sendFirebaseToken = (token) => ({
  type: types.FIREBASE_SET_TOKEN,
  token
})

export const listenBackgroundProcess = () =>  ({
  type: types.LISTEN_BACKGROUND_PROCESS
})


export const startBackroundFetchProfiles = () =>  ({
  type: types.START_BACKGROUND_FETCH_PROFILES
})

export const stopBackroundFetchProfiles = () =>  ({
  type: types.STOP_BACKGROUND_FETCH_PROFILES
})

export const getRatingImages = (accessToken) => ({
  type: types.RATING_IMAGES_FETCH,
  accessToken
});

export const getRatingImagesSuccess = (ratingImages) => ({
  type: types.RATING_IMAGES_FETCH_SUCCESS,
  ratingImages
});

export const getRatingImagesError = (error) => ({
  type: types.RATING_IMAGES_FETCH_FAILURE,
  error
});

export const getIdpsContentProvider = () => ({
  type: types.IDPS_CONTENT_PROVIDER_FETCH
});

export const getIdpsContentProviderSuccess = (idpsContentProviderList) => ({
  type: types.IDPS_CONTENT_PROVIDER_FETCH_SUCCESS,
  idpsContentProviderList
});

export const getIdpsContentProviderError = (error) => ({
  type: types.IDPS_CONTENT_PROVIDER_FETCH_FAILURE,
  error
});
