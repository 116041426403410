import React from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import queryString from 'query-string';

import {
  decodeToken
} from '../../utils/jwtUtils';
import usePrevious from '../../customHooks/usePrevious';
import * as actions from './actions';
import * as selectors from './selectors';
import * as profileActions from '../ProfileManager/actions'
import { getCurrentProfileData, getDefaultAdminProfile } from '../../utils/ProfileUtils';
import { requestForToken } from '../../firebase';

function _useQueryParams(location) {
  let searchString = location.search || '';

  if (searchString.startsWith('?')) {
    searchString = searchString.substr(1);
  }

  return queryString.parse(searchString);
}


/**
 * DidMount like hook effect
 *
 * @export
 */
export function useDidMount(config, currentToken) {
  const dispatch = useDispatch();
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));
  const location = useSelector(state => selectors.selectLocation(state));
  const queryParams = _useQueryParams(location);
  const { code, logout, one_time_token } = queryParams;

  const addTemporalToken = () => {
    const params = new URLSearchParams(window.location.search);
    const oneTimeToken = params.get('one_time_token');
    const cp = params.get('cp');
    params.delete('one_time_token');
    params.delete('cp');
    window.history.replaceState(null, '', (Array.from(params).length === 0) ? window.location.pathname : `?${params}${location.hash}`)
 }

  React.useEffect(() => {

    if (!authenticated) {
        code && dispatch(actions.authnLogin(code, window.location.origin));
        one_time_token && dispatch(actions.authnOTTLogin(one_time_token));
    } else if (authenticated && logout === 'true') {
        dispatch(actions.deviceLogout());
    } else if (code && authenticated){
      
        if (window.location.search.includes('code')) {
          const params = new URLSearchParams(window.location.search);
          params.delete('code');
          window.history.replaceState(null, '', (Array.from(params).length === 0) ? window.location.pathname : `?${params}${location.hash}`)
        }
    }

    if(one_time_token) {
        addTemporalToken();
    }
    else {
        dispatch(actions.appStartup());
    }
 
  }, [authenticated]);
}

export function useUserLogout() {
  const dispatch = useDispatch();
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));
  const authenticatedPrev = usePrevious(authenticated);

  React.useEffect(() => {
    if (!authenticated && authenticatedPrev) {
      dispatch(actions.appStartup());
    }
  }, [authenticated, authenticatedPrev, dispatch])
}

export function useProfiles(currentToken) {
  const dispatch = useDispatch();
  const tokenData = currentToken.access_token ? decodeToken(currentToken.access_token) : {};
  const { profile } = tokenData;
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));
  const { parentalControl } = useSelector((state)=> selectors.selectAppSettings(state));

  React.useEffect(() => {

    dispatch(actions.toggleProfilesModal(false));
    authenticated && (parentalControl || parentalControl===undefined) && !profile && dispatch(actions.toggleProfilesModal(true));
    authenticated && ((!parentalControl && parentalControl!==undefined) || profile) && dispatch(profileActions.fetchProfiles(currentToken));
    
    authenticated && profile && dispatch(profileActions.fetchFavoriteContentByProfile(currentToken));

    authenticated && profile && requestForToken(dispatch);

  }, [authenticated, profile]);

}

export function useCurrentProfileValidation(currentToken) {

  const dispatch = useDispatch();
  const tokenData = currentToken.access_token ? decodeToken(currentToken.access_token) : {};
  const { profile } = tokenData;
  const authenticated = useSelector(state => selectors.selectAuthenticated(state));
  const profileCollection = useSelector(state => selectors.selectProfileCollection(state));

  React.useEffect(()=> {
    authenticated && profile && (profileCollection.length > 0) && !getCurrentProfileData(profileCollection, profile) && dispatch(actions.toggleProfilesModal(true));
  }, [profileCollection])
}


/**
 * useNewRelicAttributes like hook effect
 *
 * @export
 */
 export function useNewRelicAttributes(accessToken) {

  React.useEffect(() => {

    if(window.newrelic) {
      const jwtData = accessToken?.access_token ? decodeToken(accessToken?.access_token) : {}

      if( Object.keys(jwtData).length > 0 ) {
        const {
            aud,
            client,
            country,
            customer,
            device,
            exp,
            iat,
            index,
            iss,
            language,
            maxRating,
            profile,
            subscriber
        } = jwtData;

        window.newrelic.setCustomAttribute("AUD",aud);
        window.newrelic.setCustomAttribute("Client",client);
        window.newrelic.setCustomAttribute("Customer",customer);
        window.newrelic.setCustomAttribute("Country", country);
        window.newrelic.setCustomAttribute("Device", device);
        window.newrelic.setCustomAttribute("EXP",exp);
        window.newrelic.setCustomAttribute("Language",language);
        window.newrelic.setCustomAttribute("MaxRating", maxRating);
        window.newrelic.setCustomAttribute("Profile",profile);
        window.newrelic.setCustomAttribute("Subscriber",subscriber);
      }
    }
    else {
      console.warn("New Relic setting is not implemented for the current environment");
   }

  }, [accessToken?.access_token]);
}

export function useRatingImages(currentToken) {
  const dispatch = useDispatch();
  
  React.useEffect(()=> {
    dispatch(actions.getRatingImages(currentToken));
  }, [])
}

export function useIdpsContentProvider() {
  const dispatch = useDispatch();

  React.useEffect(()=> {
    dispatch(actions.getIdpsContentProvider());
  }, [])
}
