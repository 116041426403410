import React from 'react';
import PropTypes from 'prop-types';
import ClientLogo from '../../static/images/logo.png';
import './styles.scss';
import useRouter from '../../customHooks/useRouter';
import { useTranslation } from "react-i18next";
import * as hooks from './hooks.js'

export function RedirectHandleError(){
    const router = useRouter()
    const { error, navBar } = router.location.state;
    return HandleError({ error, navBar })
}

export function HandleError({ error, navBar=true }) {
    const { t } = useTranslation();
    const errorTitle = (error?.error && error.error.toString()) || (error?.code && error.code.toString());
    const errorInfo = (error?.errorInfo?.componentStack) || (error?.stack) || (error?.message && error.message.toString())
    hooks.useChangeNavBarBool(navBar)


    return (
        <main className="main-content">
            <div className='tbxHandleError' >
                <div className='tbxHandleError-container'>
                    <img className="tbxHandleError-logo" src={ClientLogo} alt="AXS Play" />
                    <h3 className='tbxHandleError-title'>{t('HandleError Title')}</h3>
                    <p className='tbxHandleError-subTitle'>{t('HandleError SubTitle')}</p>
                    <details className='tbxHandleError-details' style={{ whiteSpace: 'pre-wrap' }}>
                        <summary className='tbxHandleError-summary'>ERROR CODE</summary>
                        <div className='tbxHandleError-errorText'>
                            {errorTitle}
                            <br />
                            {errorInfo}
                        </div>
                    </details>
                </div>
            </div>
        </main>
    );
}


HandleError.propTypes = {
    error: PropTypes.object,
    navBar: PropTypes.bool
};